

.btn-sub-criteria-price-cut {
    background-color: #EEEEEE;
    padding: 8px;
    text-align: center;
    width: 159px;
    cursor: pointer;
    border-radius: 8px;
}

.btn-sub-criteria-price-cut-active {
    background-color: #ffdaa2;
    padding: 8px;
    text-align: center;
    width: 159px;
    cursor: pointer;
    border-radius: 8px;
}

.btn-sub-criteria-price-cut:hover {
    background-color: #d9d9d9;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

@media (max-width: 786px) {
    .btn-sub-criteria-price-cut {
        width: 100%;
    }

    .btn-sub-criteria-price-cut-active {
        width: 100%;
    }
}