.card-evaluation-result {
    border-radius: 8px;
    border: 1px solid #EEEEEE;
}

.card-evaluation-result:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.card-purchase-item {
    border-radius: 8px;
    border: 1px solid #EEEEEE;
    padding: 24px;
}

.card-purchase-item:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.purchasing-location {
    border-radius: 8px;
    border: 1px solid #EEEEEE;
    padding: 24px;
    display: grid;
}

.purchasing-location:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
