#navbar {
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
}

.nav-item {
  padding: 0px;
}

.navbar-collapse {
  padding: 0px;
}

.dropdown-toggle {
  padding: 0px;
}

.nav-reminder {
  padding-left: 10px;
  margin-top: -5px;
}

.ant-drawer-header {
  border-radius: 0;
}

div .ant-drawer-body {
  color: #fff;
  height: calc(100vh - 55px);
  padding: 0px;
}

.divStyle {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;
  transition: background-color 0.3s;
}

.divStyle:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
